<template xmlns="http://www.w3.org/1999/html">
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(rgba(24,62,49,0.2),black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <div class="col-lg-8 col-sm-12">
        <div
          class="centered text-center"
          style="width: 100%; "
          :style="{
              background: `linear-gradient(${this.secondaryColor}, black)`,
            }"
        >
          <img
            v-lazy="
                mainImage +
                '?w=315&h=200&fit=clamp&auto=format'
              "
            style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
            width="315px"
            height="200px"
            alt="tulum epic nights"
            class="d-inline-xs d-sm-none"
          />

          <img
            v-lazy="
                 mainImage +
                '?w=615&h=250&fit=clamp&auto=format'
              "
            style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
            width="615px"
            class="d-none d-sm-inline"
            alt="tulum epic nights"
            height="250px"
          />
          <br/>

          <h1
            class="tulum-party-title"
            style="
                color: #ffffff !important;
                margin-top: 20px;
                text-transform: uppercase;
              "
          >
            ZAMNA
          </h1>
          <h2 class="tulum-party-title" style="margin-top: -30px;font-size: 15px;font-weight: normal">TULUM OFFICIAL
            SELLER</h2>

        </div>

        <div class="card centered text-center" style="margin-top: 0px">
          <p>
            <router-link :to="{path:'/brands/zamna-tulum'}">Zamna Tulum</router-link>
            is so popular, that it is not uncommon to find yourself struggling
            to <strong>find the correct and safest way to buy your tickets or reserve and pay your table</strong>.
            <br>
            <br>
            <strong>tulum.party</strong> is a Zamna
            Official Seller, which means that apart from being a verified entity and securing that our tables and
            tickets are <strong>100% risk-free</strong> of fraud.
          </p>
          <br>

          <section class="text-left">

            <h4 class="section-header">

              <img
                v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                :alt="this.title"
              />

              BENEFITS
            </h4>

            <hr
              style="height: 2px"
              :style="{ backgroundColor: this.brandColor }"
            />
            <ul>
              <li>
                Buy with Confidence. Tulum Top Promoters & We stay all year long.
              </li>
              <li>
                Support during your planning & execution phase. Talk to us.
              </li>
              <li>
                Secure & Alternate payment options. Support multiple cards & Paypal.
              </li>
            </ul>
          </section>
          <section class="text-left">

            <h4 class="section-header">

              <img
                v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                :alt="this.title"
              />

              FAQS
            </h4>

            <hr
              style="height: 2px"
              :style="{ backgroundColor: this.brandColor }"
            />
            <ul>
              <li>
                Are the tickets and tables you sell authentic?
                Yes, all our tickets and tables are 100% authentic. We are an authorized Zamna´s official seller, guaranteeing the authenticity of all our products.

              </li>
              <li>
                Is it safe to buy tickets and tables through you?
                Absolutely! Your security is our priority. We use secure payment platforms to protect your personal and financial information. We also have a dedicated customer service team to assist you at any time.
              </li>
              <li>
                What is your cancellation and refund policy?
                Due to high demand and event logistics, we do not offer refunds for ticket or table purchases.
                We recommend carefully reviewing the event information before making your purchase.
              </li>
            </ul>
          </section>

          <br>
          <br>
          <router-link :to="{path:'/brands/zamna-tulum'}">
            <OfficialSellerCard/>
          </router-link>
          <section>
            <SmartMenu :links="links" :logo="this.astroImg" top-section-name="TALK TO US" cta-text="CONTACT US"
            :primary-color="primaryColor"
            >
              <div class="card">
                <p>Want to talk with someone? <br> Drop us a line. We are here to assist.</p>
                <br>
              </div>

            </SmartMenu>
          </section>
        </div>
      </div>


      <aside class="card col-4 col-lg-4 d-none d-lg-inline text-left">
        <SmartMenu :links="links" :logo="this.astroImg" top-section-name="TALK TO US" cta-text="CONTACT US"
                   :primary-color="primaryColor">

          <div class="card">
            <p>Want to talk with some one? <br> Drop us a line. We are here to assist.</p>
          </div>


        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import OfficialSellerCard from "@/pages/OfficialSellers/OfficialSellerCard";

export default {
  name: 'ZamnaOfficialSeller',
  created: function () {
    this.$store.dispatch('getLinks', {slug: 'tulum-nightlife'});
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
  },
  props: ['slug'],
  metaInfo() {
    return {
      title: `tulum.party | Zamna Tulum Official Seller`,
      meta: [
        {
          name: 'description',
          content: `Zamna Tulum Official Seller,`,
        },
        {
          name: 'keywords',
          content: `Zamna Tulum Official Seller`,
        },
        {
          property: 'og:title',
          content: `tulum.party | Zamna Tulum Official Seller`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/zamna-tulum-official-sellers`,
        },
        {
          property: 'og:description',
          content: `tulum.party is a Zamna Tulum Official Seller`,
        },
        {property: 'og:type', content: 'article'},
        {property: 'og:locality', content: 'Tulum'},
        {property: 'og:country-name', content: 'Mexico'},
        {
          property: 'og:image',
          content:
            'https://imgix.cosmicjs.com/fb9e8cc0-46bc-11eb-8ce6-53cb9384270b-zamna-tulum.jpeg',
        },
      ],
    };
  },
  data: () => {
    return {
      showPalms: false,
      logo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      astroImg: 'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      primaryColor: '#82b92d',
      secondaryColor: '#11428b',
      bikeImg: 'https://imgix.cosmicjs.com/9562a300-47ef-11ef-a504-63e081e4680f-animated-bike.webp',
      batey:
        'https://imgix.cosmicjs.com/d6016a50-bd25-11ed-b7c8-73c00a6d38c7-image.png',
      zamnaImg:
        'https://imgix.cosmicjs.com/8ab84f40-5c05-11ed-a620-259cb7f1b280-telegram-cloud-photo-size-1-5125400631887112900-y.jpg',
      followThatDreamImg:
        'https://imgix.cosmicjs.com/89de8e60-9a96-11ee-b34c-99d1d89e42eb-IMG5765.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      mainImage:
        'https://imgix.cosmicjs.com/fb9e8cc0-46bc-11eb-8ce6-53cb9384270b-zamna-tulum.jpeg',
    };
  },
  components: {
    OfficialSellerCard,
    SmartMenu
  },
  methods: {},
};
</script>

